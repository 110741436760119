body {
  padding: 0px;
  margin: 0px;
  text-align: center;
  font-family: sans-serif;
}

.upload-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

iframe {
  width: 100%;
  height: 100vh;
  border: 0px;
  display: block;
}

input {
  margin: 20px;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

input[type="submit"] {
  width: 120px;
  height: 50px;
  background: skyblue;
}

input[type="submit"]:hover {
  filter: brightness(1.05);
}

.link-output {
  font-size: 30px;
}

.last-links {
  background-color: #fafafa;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  max-width: 300px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  max-height: 100vh;
  padding: 0px;
}

.last-links li {
  text-align: left;
  margin-top: 10px;
}
